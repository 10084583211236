import React from "react";

import ContactUs from "./ContactUs";

const Contact = () => {
   

    return (
        <div>
            
            <div className="main-content">
                <div className="layout">
                    <ContactUs/>
                   
                </div>
            </div>
        </div>
    );
};

export default Contact;
